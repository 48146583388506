<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron cambios"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'three-line': true }"
      :loading="loadingData"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      :page-loaded="pageLoaded"
      pk-name="sync.idsync"
    >
      <template
        #listItem="slotProps"
      >
        <sync-list-item
          :item="slotProps.item"
          :index="slotProps.index"
          @click-delete="clickDelete"
        />
      </template>
    </b10-list>
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './SyncListData'
import SyncListItem from './components/SyncListItem'
import { get } from 'vuex-pathify'

const pageStoreName = 'pagesOfflineSyncList'

export default {
  components: {
    SyncListItem,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
    usuarioIdusuario: get('usuario/idusuario'),
  },
  async created () {
    await this.initStore()
    await this.initDB()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = 'Cambios pendientes'
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectSync(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.usuarioIdusuario
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        await this.reloadItemsBase(
          await Data.selectSyncRows(this, dirty.modified), dirty, 'sync.idsync'
        )
      } finally {
        this.loadingData = false
      }
    },
    async clickDelete (data) {
      const res = await this.$alert.showConfirm(
        `¿Deseas eliminar el cambio pendiente "${data.item.sync.method}"?`
      )
      if (res) {
        await Data.delete(this, data.item.sync.idsync)
        await this.dispatchStore('deleteItem', data.index)
      }
    },
  }
}
</script>
